/**
 * Created by yuchimin on 2018/5/31.
 */

import axios from 'axios'

export const createEvent = params => { return axios.post('/wx3pi/oauth/event/create', params).then(res => res.data) }
export const updateEventSecretKey = params => { return axios.post('/wx3pi/oauth/event/secretkey/update', params).then(res => res.data) }
export const getEventById = id => { return axios.get('/wx3pi/oauth/event/get', {params: {id}}).then(res => res.data) }
export const getEventList = () => { return axios.get('/wx3pi/oauth/event/list').then(res => res.data) }
export const clearEventCache = id => { return axios.get('/wx3pi/oauth/event/cache/clear', {params: {id}}).then(res => res.data) }

export const getWechatById = id => { return axios.get('/wx3pi/wechat/get', {params: {id}}).then(res => res.data) }
export const getWechatList = () => { return axios.get('/wx3pi/wechat/list').then(res => res.data) }

