<template>
  <div>
    <b-jumbotron header="" lead="微信公众号授权" >
      <p>将公众号授权给【全流量时代】微信营销平台</p>
      <p>仅仅只需一步、剩下的就交给我们吧！</p>
      <b-btn variant="success" :href="`/wx3pi/auth/bridge?url=${callback_url}`">去授权</b-btn>
    </b-jumbotron>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        callback_url: ''
      }
    },
    components: {},
    mounted () {
      this.callback_url = this.$route.query.url || ''
    },
    methods: {}
  }
</script>
<style scoped>
</style>
