<template>
  <div>
    <b-jumbotron header="" lead="账号信息">
      <b-list-group style="text-align: left">
        <b-list-group-item><b-badge>AppID</b-badge>  {{result.id}}</b-list-group-item>
        <b-list-group-item><b-badge>名称</b-badge>  {{result.nick_name}}</b-list-group-item>
        <b-list-group-item><b-badge>别名</b-badge>  {{result.alias}}</b-list-group-item>
        <b-list-group-item><b-badge>企业</b-badge>  {{result.principal_name}}</b-list-group-item>
        <b-list-group-item><b-badge>秘钥</b-badge>  {{result.secret_key}}</b-list-group-item>
      </b-list-group>
    </b-jumbotron>
  </div>
</template>
<script>
  import {getWechatById} from '../api/index'
  export default {
    data () {
      return {
        result: {}
      }
    },
    components: {},
    mounted () {
      this.getWechatInfo()
    },
    methods: {
      async getWechatInfo () {
        let id = this.$route.query.id
        if (id) {
          let res = await getWechatById(id)
          if (res.status === 0) {
            /* 成功 */
            this.result = res.data
          } else {
            /* 失败 */
            alert(res.message)
          }
        }
      }
    }
  }
</script>
<style scoped>
</style>
