<template>
  <div>
    <b-table striped hover :items="items" :fields="fields">
      <template #cell(id)="data">
        <b-link :to="`/WechatInfo?id=${data.value}`">{{data.value}}</b-link>
      </template>
      <template #cell(service_type)="data">
        <b-badge :variant="data.value | serviceTypeStyle">{{data.value | serviceTypeName}}</b-badge>
      </template>
      <template #cell(auth_status)="data">
        <b-badge :variant="data.value==1?'success':'warning'">{{data.value==1?'已认证':'未认证'}}</b-badge>
      </template>
    </b-table>
  </div>
</template>
<script>
    import {getWechatList} from '../api/index'
    export default {
      components: {},
      data () {
        return {
          items: [],
          fields: [
            { key: 'id', label: 'AppID' },
            { key: 'nick_name', label: '公众号' },
            { key: 'service_type', label: '类型' },
            { key: 'user_name', label: '原始ID' },
            { key: 'principal_name', label: '企业' },
            { key: 'auth_status', label: '认证状态' },
            { key: 'createdAt', label: '创建时间', sortable: true, sortDirection: 'desc' }
          ]
        }
      },
      filters: {
        serviceTypeStyle(type) {
          switch(type) {
            case 0:
              return "";
            case 1:
              return "warning";  
            case 2:
              return "success"; 
            default:
              return ""; 
          }
        },
        serviceTypeName(type) {
          switch(type) {
            case 0:
              return "小程序";
            case 1:
              return "订阅号";  
            case 2:
              return "服务号"; 
            default:
              return "未知"; 
          }
        }
      },
      mounted () {
        this.getWechatList()
      },
      methods: {
        async getWechatList () {
          let res = await getWechatList()
          if (res.status === 0) {
            /* 成功 */
            if (res.status === 0 && res.data && res.data.length > 0) {
              this.items = res.data
            }
          } else {
            /* 失败 */
            alert(res.message)
          }
        }
      }
    }
</script>
<style scoped>
</style>
