import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/components/Index'
import WechatInfo from '@/components/WechatInfo'
import WechatList from '@/components/WechatList'
// import EventCreate from '@/components/EventCreate'
// import EventList from '@/components/EventList'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index
    },
    {
      path: '/WechatList',
      name: 'WechatList',
      component: WechatList
    },
    {
      path: '/WechatInfo',
      name: 'WechatInfo',
      component: WechatInfo
    },
    // {
    //   path: '/EventCreate',
    //   name: 'EventCreate',
    //   component: EventCreate
    // },
    // {
    //   path: '/EventList',
    //   name: 'EventList',
    //   component: EventList
    // }
  ]
})
